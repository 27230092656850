
import { computed, defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import { VueEditor } from "vue3-editor";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { AxiosRequestConfig } from "axios";
import { useI18n } from "vue-i18n";
import { event } from "vue-gtag";

interface Research {
  trimester: string;
  subject: string;
  title: string;
  module: string;
  desc: string;
  deadline: string;
  teachers: string[];
  levels: string[];
}

interface Subject {
  _id: string;
  name: string;
}

interface Teacher {
  _id: string;
  firstName: string;
  lastName: string;
  employee: {
    firstName: string;
    lastName: string;
  };
  levels: string[];
  subjects: string[];
}

export default defineComponent({
  name: "add-my-research",
  components: {
    ErrorMessage,
    Field,
    Form,
    VueEditor,
  },
  props: {
    id: String,
  },
  setup() {
    const { t } = useI18n();

    const uploadPercentage = ref(0);

    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    // eslint-disable-next-line
    const upload = ref<any>(null);

    const store = useStore();
    const router = useRouter();

    const subjects = computed(() =>
      store.getters.currentUser.subjects.filter((s) => s.status !== "inactive")
    );
    const levels = computed(() => store.getters.currentUser.levels);

    const teacherList = ref<Teacher[]>([]);

    const jwtTrimester = JwtService.getTrimester();
    let trimester = "1";
    if (jwtTrimester && jwtTrimester != "0") trimester = jwtTrimester;

    const research = ref<Research>({
      trimester,
      subject: "",
      title: "",
      module: "",
      desc: "",
      deadline: "",
      teachers: [],
      levels: [],
    });

    const spec = ref<string>("0");

    const validationSchema = Yup.object().shape({
      title: Yup.string().required(t("course.requiredTitle")),
      subject: Yup.string().required(t("course.requiredSubject")),
      teachers: Yup.array().test(
        "required-teachers",
        t("course.requiredTeachers"),
        (value) => {
          if (spec.value == "0") return true;
          if (spec.value == "1" && (value?.length == 0 || value == undefined))
            return false;
          else return true;
        }
      ),
      levels: Yup.array()
        .min(1, t("course.requiredLevels"))
        .required(t("course.requiredLevels")),
    });

    // eslint-disable-next-line
    const uploadFiles = ref<any>([]);

    const updateUploadFiles = (file, files) => {
      uploadFiles.value = files;
    };

    const submit = () => {
      const data = new FormData();

      if (!research.value.deadline) research.value.deadline = "";

      if (research.value.teachers.length != 0) {
        research.value.teachers.forEach((st: string) => {
          data.append("teachers", st);
        });
      }

      for (const name in research.value)
        if (name != "teachers" && name != "levels")
          data.append(name, research.value[name]);

      uploadFiles.value.forEach((file) => {
        data.append("resources", file.raw, file.raw.name);
      });

      if (research.value.levels.length != 0) {
        research.value.levels.forEach((cr: string) => {
          data.append("levels", cr);
        });
      }

      data.append(
        "schoolarYear",
        localStorage.getItem("activeSchoolarYear") as string
      );

      if (!submitButtonRef.value) {
        return;
      }

      //Disable button
      submitButtonRef.value.disabled = true;
      // Activate indicator
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");

      ApiService.put(
        "/lms/advisor/research",
        data as AxiosRequestConfig,
        {
          onUploadProgress: function (progressEvent) {
            uploadPercentage.value = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
          },
        } as any
      )
        .then(({ data }) => {
          event("Add research", {
            event_category: "Research",
            event_label: "Research section",
            value: 1,
          });

          if (submitButtonRef.value) {
            submitButtonRef.value.disabled = false;

            submitButtonRef.value?.removeAttribute("data-kt-indicator");
          }

          Swal.fire({
            text: t("course.addedResearch"),
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "ok",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          }).then(() => {
            router.push(`/research/${data._id}`);
          });
        })
        .catch(() => {
          Swal.fire({
            text: t("course.errorText"),
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: t("course.tryAgain"),
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
          if (submitButtonRef.value) {
            submitButtonRef.value.disabled = false;

            submitButtonRef.value?.removeAttribute("data-kt-indicator");
          }
        });
    };

    ApiService.post("/schedules/filter", {
      query: {
        status: { $ne: "inactive" },
        hidden: { $ne: true },
      },
      aggregation: [
        {
          $lookup: {
            from: "teachers",
            localField: "teacher",
            foreignField: "_id",
            as: "teacher",
          },
        },
        {
          $lookup: {
            from: "teachers",
            localField: "teacher.employee",
            foreignField: "_id",
            as: "teacherName",
          },
        },
        {
          $lookup: {
            from: "classrooms",
            localField: "classeRoom",
            foreignField: "_id",
            as: "classeRoom",
          },
        },
        {
          $lookup: {
            from: "modules",
            localField: "module",
            foreignField: "_id",
            as: "module",
          },
        },
        {
          $lookup: {
            from: "modulespecifics",
            localField: "moduleSpecific",
            foreignField: "_id",
            as: "moduleSpecific",
          },
        },
        {
          $project: {
            _id: 1,
            teacherName: {
              firstName: 1,
              lastName: 1,
            },
            teacher: {
              $let: {
                vars: {
                  teacherVar: {
                    $arrayElemAt: ["$teacher", 0],
                  },
                },
                in: {
                  _id: "$$teacherVar._id",
                  employee: "$$teacherVar.employee",
                },
              },
            },
            classeRoom: {
              $let: {
                vars: {
                  classVar: {
                    $arrayElemAt: ["$classeRoom", 0],
                  },
                },
                in: {
                  name: "$$classVar.name",
                  _id: "$$classVar._id",
                  level: "$$classVar.level",
                },
              },
            },
            module: {
              $let: {
                vars: {
                  moduleVar: {
                    $arrayElemAt: ["$module", 0],
                  },
                },
                in: {
                  name: "$$moduleVar.name",
                  _id: "$$moduleVar._id",
                  status: "$$moduleVar.status",
                  subjects: "$$moduleVar.subjects",
                },
              },
            },
            moduleSpecific: {
              $let: {
                vars: {
                  moduleSpecificVar: {
                    $arrayElemAt: ["$moduleSpecific", 0],
                  },
                },
                in: {
                  name: "$$moduleSpecificVar.name",
                  _id: "$$moduleSpecificVar._id",
                  status: "$$moduleSpecificVar.status",
                  subjects: "$$moduleSpecificVar.subjects",
                },
              },
            },
            subject: 1,
            type: 1,
          },
        },
      ],
    })
      .then(({ data }) => {
        data = data
          .map((d) => {
            if (!d.type) d.type = "S";
            return d;
          })
          .filter(
            (d) =>
              (!d.subject || d.subject.status !== "inactive") &&
              (!d.module || d.module.status !== "inactive") &&
              (!d.moduleSpecific || d.moduleSpecific.status !== "inactive")
          );
        data.forEach((data) => {
          const teacherSubjects =
            data.type == "S"
              ? [data.subject]
              : data.type == "MP"
              ? data.module.subjects
              : data.moduleSpecific.subjects;
          data.classeRoom.level = String(data.classeRoom.level);
          const t = teacherList.value.find(
            (teacher) => teacher._id == data.teacher._id
          );
          if (
            levels.value.includes(data.classeRoom.level) &&
            subjects.value.find((subject: Subject) =>
              teacherSubjects.includes(subject._id)
            )
          ) {
            if (!t) {
              data.teacher.levels = [data.classeRoom.level];
              data.teacher.subjects = teacherSubjects;
              teacherList.value.push(data.teacher);
            } else {
              if (!t.levels.includes(data.classeRoom.level))
                t.levels.push(data.classeRoom.level);
              t.subjects.push(...teacherSubjects);
            }
          }
        });
        teacherList.value.map((t) => {
          t.subjects = t.subjects.filter(
            (s, index) => t.subjects.indexOf(s) === index
          );
        });
      })
      .catch((e) => console.log(e));

    const clearTeachers = () => {
      research.value.teachers = [];
    };

    const avaliableTeachers = computed(() =>
      teacherList.value.filter((t: Teacher) =>
        t.levels.find((l) => research.value.levels.includes(l))
      )
    );

    const customColorMethod = (percentage: number) => {
      if (percentage < 30) {
        return "#909399";
      }
      if (percentage < 70) {
        return "#e6a23c";
      }
      return "#67c23a";
    };

    return {
      avaliableTeachers,
      spec,
      clearTeachers,
      t,
      levels,
      subjects,
      upload,
      research,
      validationSchema,
      submit,
      submitButtonRef,
      updateUploadFiles,
      uploadPercentage,
      customColorMethod,
    };
  },
});
